import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import ContactForm from "../components/contactForm";
import RedirectButton from "../components/redirect-button";
import EmailLink from "../components/email-link";

import { FaPhoneAlt, FaRegCalendarCheck, FaRegEnvelope } from "react-icons/fa";

const ContactUs = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          financingProviderUrl
          phoneLink
          phoneStyled
        }
      }
    }
  `);
  const { site } = data;
  return (
    <Layout contactForm={false} logo={false} map={true} other={true}>
      <SEO
        title="Contact Multnomah Medical Clinic - Premier Shockwave Therapy Treatment"
        description="Get in touch with our wonderful staff to schedule an appointment or to learn more about the ED treatments we offer."
      />
      <div className="flex flex-col justify-center">
        <h1 className="text-3xl font-bold px-4 text-center pb-4">Book Now</h1>
        <div className="w-full px-3 flex flex-col justify-center">
          {/*<div className="border-2 rounded mb-4 w-full max-w-lg self-center">
            <RedirectButton
              buttonID="contact_form"
              noStyle
              classes="w-full hover:bg-gray-200"
            >
              <div>
                <div className="flex items-center p-4">
                  <div>
                    <FaRegCalendarCheck
                      className="mr-3 h-10 w-10"
                      style={{ fill: "black" }}
                    />
                  </div>
                  <div>
                    <h2 className="text-blue-500 text-left text-xl">
                      Schedule an appointment online
                    </h2>
                  </div>
                </div>
              </div>
            </RedirectButton>
          </div>*/}
          <div className="border-2 rounded mb-4 w-full max-w-lg self-center hover:bg-gray-200">
            <a href={`tel:${site.siteMetadata.phoneLink}`}>
              <div className="flex items-center p-4">
                <div>
                  <FaPhoneAlt
                    className="mr-3 h-10 w-10"
                    style={{ fill: "black" }}
                  />
                </div>
                <div>
                  <h2 className="text-blue-500 text-left text-xl">
                    Give us a call
                  </h2>
                  <p>
                    Phone:
                    <a
                      href={`tel:${site.siteMetadata.phoneLink}`}
                      className="text-blue-500 font-medium"
                    >
                      {site.siteMetadata.phoneStyled}
                    </a>
                  </p>
                </div>
              </div>
            </a>
          </div>
          <div className="border-2 rounded p-4 w-full max-w-lg self-center">
            <div className="flex items-center mb-3">
              <div>
                <FaRegEnvelope
                  className="mr-3 h-10 w-10"
                  style={{ fill: "black" }}
                />
              </div>
              <div>
                <h2 className="text-blue-500 text-left text-xl">
                  Book Your Appointment
                </h2>
                <p>Schedule directly through our form below.</p>
              </div>
            </div>
            <div>
              <ContactForm />
            </div>
          </div>
          <div
            className="w-full pt-12 mb-8 max-w-md self-center"
            style={{ borderBottom: "1px solid rgba(0,0,0,0.2)" }}
          ></div>
          <div>
            <div className="flex flex-col items-center text-center">
              <a
                href={site.siteMetadata.financingProviderUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="font-bold text-2xl pb-4">Need Financing? </div>
                <button
                  style={{ minWidth: 300 }}
                  className="footer-redirect-button"
                >
                  Visit our financing provider
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ContactUs;
